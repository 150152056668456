import { RiMacLine } from "react-icons/ri";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import "./locomotive-scroll.css";
import "./App.css";

import logo from "./img/logo-bruna.png";
import ProfileImg from "./img/Bruna-Melo-Perfil.jpg";
import SessionHappyImg from "./img/session-happy.jpg";
import WhatsappImg from "./img/whatsapp-logo.png";
import MailImg from "./img/mail-logo.png";
import { useCallback, useRef, useState } from "react";
import { toaster } from "evergreen-ui";

interface SignInFormData {
  name: string;
  email: string;
  message: string;
}
function App() {
  const formRef = useRef<FormHandles>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = useCallback(
    async (_data: SignInFormData) => {
      const formData = {
        name,
        email,
        phone,
        message,
      };
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required("Informe seu name"),
          email: Yup.string()
            .required("Informe seu email")
            .email("Digite um email válido"),
          message: Yup.string().required("Deixe uma mensagem"),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        setIsDisabled(true);

        await fetch("https://ntfy.sh/brunapsi-site", {
          method: "POST",
          body: `Nova mensagem de Site Bruna Psi!\nNome: ${name}
                 \nEmail: ${email}
                 \nTelefone: https://wa.me/55${phone}
                 \nMensagem: ${message}`,
          headers: {
            Priority: "4",
            Tags: "loudspeaker",
          },
        });

        toaster.success("Mensagem enviada com sucesso", {
          description: "Logo entraremos em contato!",
          duration: 10,
        });

        setTimeout(() => setIsDisabled(false), 10000);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toaster.danger("Oops, não conseguimos enviar sua mensagem", {
            description: "Por favor, tente novamente.",
            duration: 10,
          });

          formRef.current?.setErrors({});

          return;
        }
      }
    },
    [email, message, name, phone]
  );

  return (
    <div className="container">
      <main id="inicio">
        <header>
          <img
            className="logo"
            src={logo}
            alt="logo de Bruna Melo Psicologia"
          />
          <ul className="nav-mobile">
            <li>
              <a href="#inicio">Inicio</a>
            </li>
            <li>
              <a href="#sobre">Sobre</a>
            </li>
            <li>
              <a href="#abordagem">Abordagem</a>
            </li>
            <li>
              <a href="#servicos">Serviços</a>
            </li>
            <li>
              <a href="#contato">Contato</a>
            </li>
          </ul>
          <a
            className="btn-header"
            href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta. "
          >
            Agendar Consulta
          </a>
        </header>

        <div className="wrapper">
          <h1>Tenha uma vida que vale a pena ser vivida!</h1>
          <p className="subtitle">
            Estou aqui para ajudar você a conquistar uma vida mais plena e
            significativa.
          </p>
          <div className="flex subtitle">
            <RiMacLine color="#333" />
            <p>Atendimento online para adolescentes e adultos</p>
          </div>
          <a
            className="cta"
            href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta. "
          >
            Agendar Consulta Online
          </a>
        </div>
      </main>

      <section id="sobre" className="about flex">
        <div className="resume">
          <span>PSICÓLOGA</span>
          <h1>Bruna Melo</h1>
          <strong>(CRP 06/162283)</strong>
          <p>
            Sou Psicóloga formada em Terapia Cognitivo Comportamental pelo
            Instituto de Psiquiatria da Faculdade de Medicina da USP, com
            expertise em terapias de terceira geração, notadamente a Terapia
            Comportamental Dialética (DBT). Essa abordagem trouxe resultados
            notáveis no tratamento de pacientes com desregulação emocional.
            Minha abordagem terapêutica é complementar, permitindo adaptar o
            tratamento de acordo com as necessidades e características
            específicas de cada cliente, sempre buscando oferecer um
            acompanhamento individualizado e eficaz.
          </p>
          <p>
            Possuo vasta experiência em atendimentos clínicos, trabalhando com
            pessoas que enfrentam problemas como depressão, ansiedade, burnout,
            transtorno de personalidade borderline, transtorno opositor
            desafiador, entre outros. Meu público abrange crianças, adolescentes
            e adultos, além de oferecer orientação parental. Meu principal
            objetivo é criar um ambiente acolhedor e respeitoso, onde possamos
            trabalhar juntos para promover seu bem-estar emocional e alcançar
            mudanças na sua vida. Estou aqui para auxiliar em sua jornada de
            crescimento e autoconhecimento.
          </p>
        </div>

        <div className="img">
          <img src={ProfileImg} alt="Foto de perfil Psicóloga Bruna Melo" />
        </div>
      </section>

      <section className="col">
        <span>Quando contratar uma psicóloga?</span>
        <h1 className="title">
          Estou aqui para te ajudar em todos os estágios da sua vida
        </h1>

        <div className="reasons">
          <div className="line-3">
            <div className="reason-item">
              <strong>Dificuldades Emocionais ou Mentais</strong>
            </div>

            <div className="reason-item">
              <strong>Mudanças Significativas na Vida</strong>
            </div>

            <div className="reason-item">
              <strong>Dificuldades nos Relacionamentos</strong>
            </div>
          </div>

          <div className="line-3">
            <div className="reason-item">
              <strong>Baixa Autoestima ou Confiança</strong>
            </div>

            <div className="reason-item">
              <strong>Comportamentos Destrutivos</strong>
            </div>

            <div className="reason-item">
              <strong>Desenvolvimento Pessoal</strong>
            </div>
          </div>
        </div>
      </section>

      <section id="abordagem" className="about flex">
        <div className="img">
          <img src={SessionHappyImg} alt="Foto de uma sessaão em andamento" />
        </div>

        <div className="resume">
          <span>COMO FUNCIONA?</span>
          <h1>Abordagem</h1>
          <strong>(CRP 06/162283)</strong>
          <p>
            A terapia cognitivo comportamental (TCC) é uma abordagem terapêutica
            amplamente utilizada no campo da psicologia e saúde mental.
            Desenvolvida originalmente por Aaron T. Beck e posteriormente
            expandida por outros profissionais, a TCC é fundamentada na ideia de
            que os pensamentos, emoções e comportamentos estão interconectados e
            se influenciam mutuamente a forma como uma pessoa percebe e reage ao
            mundo ao seu redor.
          </p>
          <p>
            Essa abordagem é estruturada e orientada para alterar objetivos, com
            base na ideia de que os indivíduos podem desenvolver estratégias
            para padrões de pensamento disfuncionais e comportamentais
            problemáticos. O foco principal da TCC é ajudar as pessoas a
            identificar e modificar padrões de pensamentos negativos e
            distorcidos, bem como comportamentos disfuncionais, para que possam
            alcançar mudanças positivas em suas vidas.
          </p>

          <a
            className="cta"
            href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta. "
          >
            Agendar Consulta Online
          </a>
        </div>
      </section>

      <section id="servicos" className="services col">
        <span>ÁREAS DE ATUAÇÃO</span>
        <h2 className="title">
          Acolho indivíduos que enfrentam diversas questões emocionais e
          desafios, oferecendo suporte e orientação.
        </h2>

        <div className="parent">
          <div className="bg-grid div1 filter" id="adultos">
            <div className="more-details">Adultos</div>
          </div>

          <div className="bg-grid div2 filter" id="adolescente">
            <div className="more-details">Adolescentes</div>
          </div>

          <div className="bg-grid div3 filter" id="parental">
            <div className="more-details">Orientação Parental</div>
          </div>
        </div>

        <div>
          <h2>Eu posso te ajudar?</h2>
          <p>Caso possua alguns desses casos, entre em contato comigo.</p>
        </div>

        <a
          className="cta"
          href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta. "
        >
          Agendar Consulta Online
        </a>
      </section>

      <section id="depoiments">Depoimentos</section>

      <section id="contato" className="about flex">
        <div className="resume spacing">
          <span>VAMOS CONVERSAR?</span>
          <h2>
            Meu objetivo é fazer você alcançar o equilíbrio emocional,
            possibilitando uma vida mais saudável e significativa.
          </h2>
          <p>
            Ofereço atendimento personalizado e confidencial para ajudá-lo a
            enfrentar desafios emocionais, comportamentais e psicológicos.
          </p>

          <div className="details-contact ">
            <strong>Canais de atendimento:</strong>
            <div className="dtn">
              <a href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta.">
                <img src={WhatsappImg} alt="Logotipo do Whatsapp" />
                (11) 9 6224-2778
              </a>
            </div>

            <div className="dtn mail">
              <a href="mailto:bruna@brunamelopsicologia.com.br">
                <img src={MailImg} alt="Ícone de email" />
                bruna@brunamelopsicologia.com.br
              </a>
            </div>
          </div>
        </div>

        <div className="img">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Me envie uma mensagem</h2>
            <label htmlFor="name"></label>
            <input
              type="text"
              placeholder="ex: Bruna Melo"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label htmlFor="email"></label>
            <input
              type="email"
              placeholder="ex: bruna@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="phone"></label>
            <input
              type="phone"
              placeholder="ex: 11 00000-0000"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />

            <label htmlFor="message"></label>
            <textarea
              value={message}
              placeholder="Como eu posso te ajudar?"
              onChange={(e) => setMessage(e.target.value)}
              required
            />

            {isDisabled ? (
              <button disabled type="submit">
                Enviar
              </button>
            ) : (
              <button type="submit">Enviar</button>
            )}
          </Form>
        </div>
      </section>

      <div>
        <a href="https://wa.me/5511962242778?text=Ol%C3%A1!%0AGostaria%20de%20agendar%20uma%20consulta.">
          <img className="whatsapp-btn" src={WhatsappImg} alt="" />
        </a>
      </div>

      <footer>
        <p>© 2023 Bruna Melo Psicologia. Todos os direitos reservados.</p>
        <p>
          Desenvolvido por <a href="https://voxco.netlify.app/">Voxco</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
